





























import { Component, Vue } from 'vue-property-decorator'

@Component

export default class Step3 extends Vue {
  private passport = ''
  private confirmPassport = ''

  private updateVal (): void {
    var prevState = this.$store.getters['walkthrough/userInfo']
    if (this.passport !== '') {
      prevState.passportNumber = this.passport.toUpperCase()
      this.$store.dispatch('walkthrough/setUserInfo', prevState)
      this.goTo()
    }
  }

  private mounted (): void {
    const userInfo = this.$store.getters['walkthrough/userInfo']
    this.passport = userInfo.passportNumber
  }

  private goTo (): void {
    this.$router.push('4')
  }
}

