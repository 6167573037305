




































import { Component, Vue } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Player,
    HighlightText,
    ContentArea,
    Title,
    ProgressLine,
    Modal
  }
})
export default class Step3 extends Vue {
  private isModal= false
  private isText = false
  private path = 0
  private videoIsEnd (): void {
    this.isText = true
    if (this.path === 1) this.isModal = true
  }

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    if (this.path) this.path--
    else this.$router.push('quidel/2/Screen_2')
  }

  private resetTimer ():void {
    this.$router.push('quidel/4')
  }

  // private checkRemotePermission (permissionData:any): void {
  //   const myWindow = (window as unknown) as any
  //   if (permissionData.permission === 'default') {
  //     console.log('BASE_URL', process.env.BASE_URL)
  //     myWindow.safari.pushNotification.requestPermission(
  //       process.env.BASE_URL,
  //       'web.com.bellalovesme.health.qvue.travel',
  //       {}, this.checkRemotePermission)
  //   } else if (permissionData.permission === 'denied') {
  //     console.log('Permission denied')
  //   } else if (permissionData.permission === 'granted') {
  //     console.log('Permission granted')
  //   }
  // }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[5]
  }

  private openModal (): void {
    if (sessionStorage.getItem('timer/quidel/4') === 'isDone' || sessionStorage.getItem('timer/quidel/4') === 'isRun') {
      this.$router.push('4')
    } else {
      this.isModal = true
    }
  }

  private destroyed (): void {
    if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
  }
}
